<template>
  <section
    ref="participants"
    class="section section--participant section--notRadius"
  >
    <section class="participant-gsap">
      <div class="section__wrapper">
        <h2 class="text-44 text-bold title-mb">Участники премии</h2>
        <ul v-if="filteredParticipantData" class="participant-list">
          <ParticipantCard
            v-for="(participant, id) of filteredParticipantData"
            :key="id"
            :participant="participant"
          />
        </ul>
        <UiSliderButton text="Стать участником" @click="toggleModal(true)" />
      </div>
    </section>
  </section>
  <Teleport to="#teleports">
    <UiModal :on-close="toggleModal" :is-visible="isModalVisible">
      <ParticipantApplication :is-visible="isModalVisible" />
    </UiModal>
  </Teleport>
</template>
<script setup lang="ts">
const filterParticipants = (participantsArray) => {
  const uniqueParticipants = []
  const participantIds = new Set()

  for (const participant of participantsArray) {
    const participantId = participant.attributes.participant.data.id
    if (!participantIds.has(participantId)) {
      uniqueParticipants.push(participant)
      participantIds.add(participantId)

      if (uniqueParticipants.length === 8) {
        break
      }
    }
  }
  return uniqueParticipants
}

const participantData = await getParticipants()
const filteredParticipantData = computed(() => {
  return filterParticipants(participantData)
})

const isModalVisible = ref(false)

const toggleModal = (value) => {
  isModalVisible.value = value
}
</script>
<style lang="scss" scoped>
.section {
  &--participant {
    border-radius: 20px 20px 0 0;
    margin-top: -20px;
    z-index: 5;
    position: relative;
    padding: 100px 70px;
    overflow: hidden;
    @include md {
      padding: 100px 36px 120px;
    }
    @include sm {
      padding: 100px 16px 100px;
    }
  }
}
.participant-list {
  margin: 0 0 40px 0;
  min-height: 427px;
  display: flex;
  gap: 20px;
}
.participant-gsap {
  margin: 0 0 40px 0;
}
</style>
