<template>
  <div ref="mainBlock">
    <HeaderBlock />
    <MainBlock />
    <ParticipantBlock />
    <LazyNominationsBlock />
    <LazyBlackBlock />
    <LazyReviewsBlock>
      <LazyPartnersBlock />
    </LazyReviewsBlock>
    <LazyNewsBlock />
    <LazyFooterBlock />
    <UiModal :on-close="closeModal" :is-visible="isModalVisible">
      <div class="notify">{{ modalContent }}</div>
    </UiModal>
  </div>
</template>

<script setup lang="ts">
const { $gsap, $ScrollTrigger } = useNuxtApp()

const route = useRoute()
const router = useRouter()
const isModalVisible = ref(false)
const modalContent = ref("")

const closeModal = () => {
  isModalVisible.value = false
}

const slidersPerPage = ref()
const countPaddingByWindowWidth = ref()
const windowWidth = ref()

function handleSizeWindow() {
  windowWidth.value = window.innerWidth
  if (windowWidth.value >= 1081) {
    slidersPerPage.value = 4
    countPaddingByWindowWidth.value = 140 - 20
  }
  if (windowWidth.value <= 1080) {
    slidersPerPage.value = 2
    countPaddingByWindowWidth.value = 72 - 20
  }
  if (windowWidth.value <= 580) {
    slidersPerPage.value = 1
    countPaddingByWindowWidth.value = 32 - 20
  }
}

const mainBlock = ref()
const ctx = ref()
const tl = ref()

onBeforeMount(() => {
  windowWidth.value = window.innerWidth
  window.addEventListener("resize", handleSizeWindow)
  handleSizeWindow()
})
onMounted(() => {
  const blockWidth = 1300
  const itemsParticipant = $gsap.utils.toArray(".participant-card")
  const itemsExpert = $gsap.utils.toArray(".expert")
  const itemsReviews = $gsap.utils.toArray(".review-card")
  const countTotalShift = (i) => {
    let result =
      310 * i.length +
      (20 * i.length + countPaddingByWindowWidth.value) -
      windowWidth.value
    if (windowWidth.value > 1440) result += (windowWidth.value - blockWidth) / 2
    return result
  }
  ctx.value = $gsap.context(() => {
    tl.value = $gsap.timeline()

    const triggerParticipant = document.querySelector(".section--participant")
    if (triggerParticipant && itemsParticipant) {
      tl.value.to(itemsParticipant, {
        x: `-${countTotalShift(itemsParticipant)}`,
        scrollTrigger: {
          trigger: triggerParticipant,
          scrub: 1,
          pin: true,
          end: `+=${countTotalShift(itemsParticipant)}`,
          immediateRender: false,
          invalidateOnRefresh: true,
        },
      })
    }

    const triggerExperts = document.querySelector(".expert-gsap")
    if (triggerExperts) {
      tl.value.to(itemsExpert, {
        x: `-${countTotalShift(itemsExpert)}`,
        scrollTrigger: {
          trigger: triggerExperts,
          scrub: 1,
          pin: true,
          end: `+=${countTotalShift(itemsExpert)}`,
          immediateRender: false,
          invalidateOnRefresh: true,
        },
      })
    }

    const triggerReviews = document.querySelector(".section--reviews")
    if (triggerReviews) {
      tl.value.to(itemsReviews, {
        x: `-${countTotalShift(itemsReviews)}`,
        scrollTrigger: {
          trigger: triggerReviews,
          scrub: 1,
          pin: true,
          end: `+=${countTotalShift(itemsReviews)}`,
          immediateRender: false,
          invalidateOnRefresh: true,
        },
      })
    }
  }, mainBlock.value)

  const confirmation = route.query.confirmation as string
  if (confirmation) {
    isModalVisible.value = true
    switch (confirmation) {
    case "success":
      modalContent.value =
          "Ваш адрес электронной почты подтвержден. В ближайшее время с вами свяжется менеджер для подтверждения заявки на участие в Премии."
      break
    case "confirmed":
      modalContent.value = "Код уже был подтвержден."
      break
    case "error":
      modalContent.value = "Ошибка или истечение времени. Попробуйте снова."
      break
    }
    router.replace({ query: {} })
  }
})
onUnmounted(() => {
  $ScrollTrigger.getAll()?.forEach((t) => t.kill())
  ctx.value?.revert()
})
onBeforeMount(() => {
  $ScrollTrigger.getAll()?.forEach((t) => t.kill())
})
useHead({
  title: "Премия EDTECH 2024 | Спецпроект медиагруппы «Комсомольская правда»",
  meta: [
    {
      hid: "description",
      name: "description",
      content:
        "Официальный сайт премии EDTECH от медиагруппы «Комсомольская правда». Участники премии, номинации, этапы премии, экспертный совет, отзывы, партнеры, контакты.",
    },
    {
      hid: "keywords",
      name: "keywords",
      content: "Премия EDTECH, Комсомольская правда",
    },
    {
      hid: "og:title",
      property: "og:title",
      content:
        "Премия EDTECH 2024 | Спецпроект медиагруппы «Комсомольская правда»",
    },
    {
      hid: "og:locale",
      property: "og:locale",
      content: "ru_RU",
    },
    {
      hid: "og:site_name",
      property: "og:site_name",
      content: "Премия EDTECH 2024",
    },
    {
      hid: "og:image",
      property: "og:image",
      content: "/og-image.jpg",
    },
    {
      hid: "og:description",
      property: "og:description",
      content:
        "Официальный сайт премии EDTECH от медиагруппы «Комсомольская правда». Участники премии, номинации, этапы премии, экспертный совет, отзывы, партнеры, контакты.",
    },
  ],
})
</script>

<style lang="scss" scoped>
.notify {
  padding: 100px 50px;
  margin: auto 0;
  text-align: center;
}
</style>
