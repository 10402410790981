<template>
  <div id="main" class="frame">
    <div class="frame__top">
      <div class="container">
        <div class="top-title">
          <h1>Премия медиагруппы «Комсомольская правда»</h1>
          <p class="text-16">
            для тех, кто развивает online-образование и делает его
            технологичнее, доступнее и качественнее
          </p>
        </div>
        <div class="top-logo">
          <span>Я знаю:</span>
          <NuxtImg src="/img/edtech.svg" />
        </div>
      </div>
    </div>
    <div id="about" class="frame__bottom">
      <div class="container">
        <div class="bottom-title">
          <h2>
            Приглашаем участников отрасли поделиться лучшими практиками и
            разработками
          </h2>
          <p class="text-16">
            EdTech — это образование, которое невозможно представить в отрыве от
            современных технологий. Именно они позволяют создавать новые
            образовательные продукты и методики. И получать знания в любое время
            и в любом месте. Это движущая сила, которая стимулирует дальнейшее
            развитие образования, а значит и человечества в целом.
          </p>
        </div>
        <div class="bottom-slats">
          <div class="slat">
            <div>современные технологии</div>
            <span />
          </div>
          <div class="slat">
            <div>образование</div>
            <span />
          </div>
          <div class="slat">
            <div>новые методики</div>
            <span />
          </div>
          <div class="slat">
            <div>новые продукты</div>
            <span />
          </div>
          <div class="slat">
            <div>развитие</div>
            <span />
          </div>
        </div>
        <NuxtImg class="bottom-sphere" src="/img/sphere.png" />
      </div>
    </div>
  </div>
</template>

<script setup>
const { $gsap } = useNuxtApp()
onMounted(() => {
  const sphere = document.querySelector(".bottom-sphere")
  const slats = document.querySelectorAll(".slat")

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        $gsap.to(sphere, { rotation: 40, duration: 2 })
        $gsap.fromTo(
          slats,
          { opacity: 0, x: -50 },
          { opacity: 1, x: 0, stagger: 0.2, duration: 1 },
        )
        observer.unobserve(sphere)
      }
    })
  })

  observer.observe(sphere)
})
</script>

<style lang="scss" scoped>
.frame {
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    filter: blur(15px);
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      to top,
      rgba(128, 0, 255, 0.6),
      rgba(128, 0, 255, 0)
    );
    pointer-events: none;
    z-index: 4;
  }
  &__top {
    width: 100%;
    min-height: 100svh;
    background: #0e0e0e;
    position: relative;
    padding: 140px 36px 43px;
    display: flex;

    &:before {
      content: url("@/public/img/bg_main.png");
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.8;
      height: 100%;
    }
    .top-logo {
      display: flex;
      flex-direction: column;
      span {
        text-align: end;
        font-size: 22px;
        line-height: 26px;
        margin-right: 76px;
      }
      img {
        display: block;
        max-height: 222px;
        width: 100%;
      }
    }

    .top-title {
      max-width: 587px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      flex-grow: 1;
      h1 {
        font-size: 44px;
        font-weight: 700;
        line-height: 52px;
      }
      p {
        max-width: 340px;
        font-size: 16px;
      }
    }
    @include md {
      padding: 155px 36px 30px;
      &:before {
        content: url("@/public/img/bg_main_m.png");
      }

      .top-title {
        h1 {
          font-size: 32px;
          line-height: 38px;
        }
      }

      .top-logo {
        span {
          text-align: start;
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 21px;
        }
      }
    }

    @include sm {
      padding: 128px 16px 14px;
      &:before {
        content: url("@/public/img/bg_main.png");
        right: -250px;
        top: -100px;
      }

      .top-title {
        h1 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }

  &__bottom {
    min-height: 110svh;
    width: 100%;
    background: $color-purple;
    position: relative;
    padding: 184px 36px 50px;
    display: flex;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 148px;
      width: 100%;
      background: linear-gradient(#0e0e0e, #5738cf);

      @include sm {
        height: 100px;
      }
    }

    &:after {
      content: url("@/public/img/bg_frame_grid.png");
      position: absolute;
      right: 0;
      bottom: 0;

      @include md {
        content: url("@/public/img/bg_frame_grid_m.png");
      }

      @include sm {
        content: url("@/public/img/bg_frame_grid_s.png");
      }
    }
    .bottom-title {
      display: flex;
      flex-wrap: wrap;
      max-width: 1110px;
      width: 100%;
      justify-content: space-between;

      h2 {
        max-width: 600px;
        width: 100%;
        font-size: 44px;
      }

      p {
        max-width: 451px;
        width: 100%;
      }

      @include md {
        row-gap: 30px;
        h2 {
          font-size: 32px;
          line-height: 38.4px;
        }

        p {
          font-size: 16px;
          line-height: 20px;
          max-width: 579px;
        }
      }

      @include sm {
        row-gap: 20px;
        h2 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    .bottom-sphere {
      position: absolute;
      bottom: -50%;
      left: 40%;
      width: 100%;
      max-width: 845px;
      z-index: 3;

      @include md {
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -50vw;
        transform: scale(1.2);
      }

      @include sm {
        right: 0;
        left: 35%;
      }
    }

    .bottom-slats {
      display: flex;
      flex-direction: column;
      max-width: 460px;
      width: 100%;
      margin-top: 70px;
      gap: 5px;
      z-index: 4;
      .slat {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 12px;
        line-height: 16px;
        gap: 10px;
        opacity: 0;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 44px;
          width: fit-content;
          padding: 0 25px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 6px;
        }
        span {
          width: 8px;
          height: 8px;
          background: $white;
          border-radius: 50%;
        }
        &:nth-child(2) {
          margin-left: auto;
        }
        &:nth-child(3) {
          margin: -10px 0 0 160px;
        }
        &:nth-child(5) {
          margin-left: 95px;
        }

        @include md {
          &:nth-child(5) {
            margin: 0 30% 0 auto;
          }
        }

        @include sm {
          &:nth-child(5) {
            margin: 0 15% 0 auto;
          }

          &:nth-child(3) {
            margin: 0 30% 0 auto;
          }
        }
      }
    }

    @include md {
      padding: 148px 36px 50px;
    }

    @include sm {
      padding: 100px 16px 50px;
    }
  }
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  color: $white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
